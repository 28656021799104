import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { IoIosWarning, IoMdOpen } from "react-icons/io";

import Logo from "../images/logos/PayHero/PayHero_Full.svg";
import { Button, Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const TempNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  max-width: 1350px;
  padding: 0 1rem;

  img {
    height: 30px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    max-width: 90%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 100%;
  }
`;

const ErrorMessage = styled.h4`
  svg {
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 7px;
    color: #ffd62e;
  }
`;

const NotFoundPage = (props) => (
  <Layout fullPage>
    <Seo
      title="Oops... Page Not Found | 404"
      pathname={props.location.pathname}
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Container>
      <TempNav className="-center">
        <Link to="/">
          <img
            className="menu-logo"
            src={Logo}
            alt="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
          />
        </Link>
        <Button
          className="grey -md gtm-login"
          to={process.env.GATSBY_SITE_LOGINURL}
          atag
        >
          Sign In
        </Button>
      </TempNav>
      <Wrapper maxWidth={800}>
        <Box stackGap={35} className="-textCenter">
          <div>
            <h1 className="-fontLarge">Oops!</h1>
            <h2 className="-fontLight">
              The page you're looking for doesn't exist{" "}
              <span role="img" aria-label="404 Page Not Found | Sad">
                😢
              </span>
            </h2>
          </div>
          <ErrorMessage>
            <span className="-fontBold">
              <IoIosWarning />
              Error:
            </span>{" "}
            404
          </ErrorMessage>
        </Box>
      </Wrapper>
    </Container>
    <Container bg="lightGrey">
      <Wrapper maxWidth={800}>
        <Box stackGap={50}>
          <h4 css={{ fontWeight: "400" }}>Here are some helpful links:</h4>
          <Row stackGap={30}>
            <Box stackGap={30} size={50}>
              <Link className="link-floating" to="/">
                Home
              </Link>
              <a
                className="link-floating gtm-login"
                href={process.env.GATSBY_SITE_LOGINURL}
              >
                Sign In
              </a>
              <Link className="link-floating" to="/contact">
                Contact Us
              </Link>
              <Link className="link-floating" to="/blog">
                Resource Library
              </Link>
              <Link className="link-floating" to="/sitemap">
                Site map
              </Link>
            </Box>
            <Box stackGap={30} size={50}>
              <Link className="link-floating" to="/support">
                Get Support
              </Link>
              <a
                className="link-floating"
                href="https://support.payhero.co.nz/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support Centre <IoMdOpen css={{ top: "2px" }} />
              </a>
            </Box>
          </Row>
        </Box>
      </Wrapper>
    </Container>
    <Container bg="darkBlue" className="-textCenter">
      <Wrapper stackGap={50}>
        <h3 css={{ color: "#fff" }}>
          If you need more help, email us at{" "}
          <a href="mailto:support@payhero.co.nz">support@payhero.co.nz</a>.
        </h3>
      </Wrapper>
    </Container>
  </Layout>
);

export default NotFoundPage;
